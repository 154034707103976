.product-item {
    @extend .d-flex;
    @extend .flex-column;
    @extend .h-100;
    .img-container {
        @extend .col-auto;
        border: 1px solid $line-color;
        position: relative;
        padding-bottom: 100%;
        border-bottom: 0;
        img {
            position: absolute;
            top: 15px;
            left: 0;
            height: calc(100% - 30px);
            width: calc(100% - 30px);
            -o-object-fit: contain;
            object-fit: contain;
            background-color: $white;
        }
        .top &:after {
            position: absolute;
            top: 0;
            right: 0;
            display: block;
            content: "TOPPRODUCT";
            padding: 3px 10px 3px 20px;
            color: $white;
            background-color:$brown;
            //opacity: .8;
            border-bottom-left-radius: 50px;
        }
    }
    .product-info {
        @extend.col;
        background-color: $block-bg;
        border: 1px solid $line-color;
        border-top: 0;
        color: $dark-grey;
        padding: 1.5rem;
        min-height: calc(width);
        .price {
            color: $khaki;
            font-size: 1rem;
        }
        .top & {
            background-color:lighten($khaki,50%)
        }
    }
    text-decoration: none !important;
    .img-container, .product-info {
        transition: all 300ms ease;
    }
    &:hover {
        .img-container {
            border-color: $khaki;
        }
        .product-info {
            color: $khaki;
            border-color: $khaki;
        }
    }
}

.search-group {
    //-webkit-box-shadow: 5px 5px 14px -9px #000000;
    //box-shadow: 5px 5px 14px -9px #000000;

    .input-group-prepend {
        background-color: transparent;
        color: $white;
        padding: .6rem .8rem;
        border-bottom-left-radius: 1rem;
        border-top-left-radius: 1rem;
        border: 1px solid $white;
        border-right: 0;
        i {

            font-size: 1.3rem

        }
    }
    input, input:focus {
        border: 1px solid $white;
        border-top-right-radius: 1rem;
        border-bottom-right-radius: 1rem;
        background-color: transparent;
        color: $white;
        border-left: 0;
        outline: none !important;
        padding: 1.4rem;
        box-shadow: none;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
    }
    ::placeholder {
        color: $white;
    }
}

.pagination {
    display: inline-block;


 li {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    cursor: pointer;
    border: 1px solid #ffffff;
    transition: all 300ms ease;
    &:hover:not(.active-page) {
        background-color: $brown;
        border: 1px solid $brown;
        color: $white;
    }
    &.active-page {
        background-color: $brown; color: #ffffff;
        border: 1px solid #d0d0d0;
    }
}
}

section#search-bar {
    background: {
        /*image: url('/img/bg-search.jpg');*/
        color: $brown;
        size: cover;
        opacity: .6;
    }
    label {
        color: $white;
    }
}

.search-bar-webshop {
    background-color: transparent !important;
}

.search-bar-webshop .search-bar-top {
    background-color: #695446 !important;
}

.search-bar-webshop .search-bar-top,
.search-bar-webshop .search-bar-bottom {
    max-width: none;
}

.search-bar-webshop .search-bar-top > div,
.search-bar-webshop .search-bar-bottom > div {
    max-width: 1450px;
    margin-left: auto;
    margin-right: auto;
}

.search-bar-webshop .search-bar-bottom {
    background-color: rgba(234, 230, 217, 0.322);
    border-bottom: 1px solid rgba(105, 84, 70, 0.302);
}

.product-detail-title {
    border-bottom: 1px solid #cfcfcf;
    padding-bottom: 50px;
}
.qty-group {
    position: relative;
    label {
        position: absolute;
        top: -5px;
        left: -40px;
        font-size: 10px;
        text-transform: uppercase;
    }

    .qty-up {
        position: absolute;
        bottom: 4px;
        right: -3px;
        cursor: pointer;
    }

    .qty-down {
        position: absolute;
        bottom: 4px;
        left: 9px;
        cursor: pointer;
    }

    .qty-field {
        text-align: center;
        padding-top: 29px !important;
        padding-bottom: 17px !important;
        border-radius: 6px;
        outline: none !important;
        -webkit-appearance: none;
        -moz-appearance: textfield;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

}
