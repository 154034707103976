// Fonts
@import url('https://fonts.googleapis.com/css?family=Raleway:ital,wght@0,500;0,600;0,700;0,800;1,500;1,600;1,700&');
@import url('https://fonts.googleapis.com/css2?family=El+Messiri:wght@400;500;600;700&family=Raleway:ital,wght@0,500;0,600;0,700;0,800;1,500;1,600;1,700');
// Variables
@import 'variables';
@import '../vendor/fontawesome/scss/fontawesome';
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1450px
);

$grid-gutter-width: 40px;
// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~select2/dist/css/select2.min.css';
@import '~vue2-animate/src/sass/vue2-animate';

@import 'form/index';
@import "products/categories";

body {
    font-family: $main-font;
    color: $dark-grey;
    font-weight: 400;
    font-style: normal;
    font-size: 15px;
    line-height: 28.02px;
    //letter-spacing: 1.61px;
}
h1, h2, h3,
h4, h5, h6 {
    font: {
        family: $font-headers;
    }
}

header {
    @extend .d-flex;
    @extend .align-items-center;
    //height: 145px;
    background: {
        //image: url("/img/webshop-header-bgkopie.jpg");
        color: $white;
        repeat: no-repeat;
        size: cover;
        position-y: center;

    }
    padding: 0;
    .header-title {
        color: $light-grey;
    }
}
.block {
    background-color: $block-bg;
    color: $dark-grey;
    padding: 30px 30px;
    select, select:focus {
        width: 100%;
        /*background-color: $main-color;*/
        background-color: $khaki;
        opacity: 0.64;
        color: $white !important;
        font-family: $main-font !important;
        font-size: 15px !important;
        font-weight: 400;
        font-style: normal;
       // letter-spacing: 2.61px;
        line-height: normal;
        border: 0;
    }
}

.filter-title {
    /*color : $alt-color;*/
    color : $brown;
    font: {
        family: $font-headers
    }
}

.site-btn {
    display: inline-block;
    background-color: $khaki;
    transition: all 300ms ease;
    //border-radius: 12px;
    padding: 10px 35px;
    text-transform: uppercase;
    color: $white;
    border: none;
    &:hover {
        background-color: $dark-grey;
        color: $white;
    }
}

  .navbar-nav {
      letter-spacing: 1.61px;

    .nav-item {
        display: inline-block;
        .nav-link, .nav-link:visited {
            /*color:  $main-color;*/
            color:  $khaki;
            font-family: $font-headers;
            font-size: 15px;
            font-weight: 600;
            font-style: normal;
            letter-spacing: normal;
            text-transform: uppercase;
            line-height: normal;
            position: relative;
            transition: .2s ease-in-out;
            &:after {
                content: '';
                display: block;
                position: absolute;
                z-index: 1;
                bottom: 0;
                height: 1px;
                background-color: transparent;
                transition: .2s ease-in-out;
                transition-property: background-color,border-color,box-shadow,height,right;
                left: 0px;
                right: calc(102% - 15px);
                bottom: 5px;
            }
            &:hover, &.router-link-exact-active  {
                /*color: $main-color !important;*/
                color: $brown !important;
                &:after {
                    right: 0px;
                    /*background-color: #43a4d7;*/
                    background-color: $brown;
                    height: 2px;
                }
            }
        }
    }
}
#admin-subheader {
    position: relative;
    height: 200px;
    background: {
        image: url("/img/header_be.png");
        size: cover;
        position: center;
    }
    &:before {
        position: absolute;
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        bottom:0;
        left: 0;
        background-color: rgba($black, .7);
    }

    h1 {
        font: {
            family: $font-headers;
            size: 72px;
            weight: 500;
        }
        line-height: 58px;
        color: $white;

    }

}

#showroom-subheader {
    position: relative;
    height: 200px;
    background: {
        image: url("/img/header_showroom.jpg");
        size: cover;
        position: center;
    }
    &:before {
        position: absolute;
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        bottom:0;
        left: 0;
        background-color: rgba($main-color, .7);
    }

    h1 {
        font: {
            family: "El Messiri", sans-serif;
            size: 30px;
            weight: 500;
        }
        line-height: 58px;
        color: $white;

    }

}

.file-select {
    background-color: $main-color;
    color: white !important;
    box-sizing: border-box !important;
    overflow: initial !important;
    display: block;
    width: 180px;
    text-align: center;
    position: relative;
    line-height: 40px;
    transition: all 300ms ease;
    border-radius: 10px;
    &:hover {
        background-color: darken($main-color, 10%)
    }
    input {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        opacity: 0;
        -ms-filter: 'alpha(opacity=0)';
        direction: ltr;
        cursor: pointer;
        float: left;
    }
}
.navbar-brand img {
    width: 260px;
    max-width: 100%;
}


.image-preview {
    @extend .row;
    span {
        @extend .col-2;
        @extend .my-2;
        position: relative;
        overflow: hidden;
        background: {
            size:cover;
            position: center;
        };
        &:before {
            content: "";
            display: block;
            padding-bottom: 100%;
        }

    }
}




/* slider checkboxes */
$checked-color: $main-color;
$unchecked-color: $black;
.slider-checkbox {
    position: relative;
    input {
        margin: 0px;
        margin-top: 1px;
        cursor: pointer;
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity=0);
        -moz-opacity: 0;
        -khtml-opacity: 0;
        opacity: 0;
        position: absolute;
        z-index: 1;
        top: 0px;
        left: 0px;
        background: red;
        width: 70px;
        height: 40px;
        &:checked + .label {
            &:before {
                color: $checked-color;
                border: 2px solid $checked-color;
                content: "JA";
                font-weight: bold;
                padding-left: 6px;
                padding-top: 8px;
            }
            &:after {
                left: 38px;
                background-color: $checked-color;
            }
        }
    }
    .label {
        position: relative;
        padding-left: 75px;
        padding-top: 3px;
        height: 9px;
        &:before, &:after {
            position: absolute;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            border-radius: 25px;
            transition: background-color 0.3s, left 0.3s;
        }
        &:before {
            content: "NEE";
            box-sizing: border-box;
            padding-left: 32px;
            padding-top: 8px;
            font-size: 14px;
            font-weight: bold;
            line-height: 20px;
            color: $unchecked-color;
            border: 2px solid $unchecked-color;
            left: 0px;
            top: 0px;
            height: 40px;
            width: 70px;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            border-radius: 25px;
        }
        &:after {
            content: "";
            letter-spacing: 20px;
            background: $unchecked-color;
            left: 4px;
            top: 6px;
            height: 27px;
            width: 27px;
        }
    }
}

/* end slider checkboxes */


.t1 {
   padding: 12px 20px;
    border-radius: 10px;
    border: 2px solid #303030;
    transition: all 300ms ease;
    display: inline-block;
    color: #303030;
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: none !important;
    &:hover {
        color: $white;
        background-color: #303030;
    }
}

.t2 {

    padding: 12px 20px;
    border-radius: 10px;
    border: 2px solid $gray-500;
    transition: all 300ms ease;
    display: inline-block;
    color: $gray-500;
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: none !important;
    &:hover {
        color: #303030;
        background-color: $gray-500;
    }
}

.admin-page-title {
    display: block;
    text-align: center;
    position: relative;
    color: #303030;
    font-family: Raleway;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    padding-bottom: 15px;
    &:after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        background: {
            image: url("../img/title_after.png");
            size:100%;
            repeat: no-repeat;
        };
        width: 83px;
        height: 19px;

    }
}

.dataTable {
    width: 100%;

}

.dataTables_wrapper {
    clear: both;
    table tr td {
        vertical-align: middle !important;
    }
}

.dataTables_length, .dataTables_info {
    float: right;
    width: auto;
    display: flex;
    margin: 30px 0;
    select {
        width: auto;
        margin: 0 10px;
    }
}

.dataTables_filter,.dataTables_paginate {
    width: 50%;
    float: left;
    margin: 30px 0;
    input {
        margin-left: 10px;
        border: 1px solid $gray-300;
        border-bottom: 1px solid $khaki ;
        padding: 10px;
        width: auto;
    }
}

.vueperslides__bullet, .vueperslides__bullets button {
    color: $khaki !important;
}

.dataTables_paginate {
    .paginate_button {
        display: inline-block;
        padding: 5px 10px;
        margin-right: 3px;
        border-radius: 2px;
        border: 1px solid $gray-400;
        background-color: $gray-200;
        cursor: pointer;
        &.disabled {
            border: 1px solid $gray-300;
            color: $gray-300;
            cursor: not-allowed;
        }
        &.current {
            border-bottom-color: $main-color;
        }

    }
}

.alert {
    text-align: center;
}


h3.admin-subtitle {
    font-family: "El Messiri" !important;
    font-size: 36px !important;
}


footer {

    padding-top: 24px;
    padding-bottom: 24px;
    background: $brown;
    color: $white;
    font-family: $main-font;
    font-size: 13px;
    //font-weight: 700;
    font-style: normal;
    letter-spacing: normal;
    a {
        color: $white !important;
    }
    ul {
        margin-bottom: 0;
        list-style-type: none;
        padding-left: 0;
        display: block;
        & > li, & > li > a  {

            color: $white;
            font-family: $main-font;
            font-size: 13px;
            //font-weight: 700;
            font-style: normal;
            letter-spacing: 1.34px;

        }
        @include media-breakpoint-up(lg) {



            &.footer-right {

                text-align: right;

            }

        }

    }

}


//.nav-item {
//    position: relative;
//    transition: all 300ms ease;
//    &:after {
//        position: absolute;
//        content: "";
//        top: 50px;
//        left: 8px;
//        width: 0%;
//        height: 2px;
//        background-color: $main-color;
//        transition: all 300ms ease;
//    }
//    &.active:after, &:hover:after {
//        width: calc(100% - 16px);
//
//    }
//}


.cart-item {

    .block {
        background-color: $block-bg;
        color: $black;
        border: 1px solid #cccc;
        padding: 10px;
        margin-bottom: 15px;
    }
    a.remove-from-cart {
        display: inline-block;
        position: relative;
        padding: 4px 0 4px 30px;
        color: $black;
        &:before {
            position: absolute;
            left: 0;
            top: 3px;
            content: "";
            width:18px;
            height: 22px;
            background: {
                image: url("/img/rubbish-can.png");
                size:100%;
                position:center;
                repeat: no-repeat;
            };
        }
    }
    .item-price {
        font-family: Raleway;
        font-size: 18px;
        font-weight: 700;
        line-height: 19px;
        color: $khaki;
    }
}

.order-btn {
    border-radius: 0 !important;
}

.border {
    border: 1px solid #ccc;
    padding: 20px 30px;
}

.border-top {
    border-top: 1px solid #ccc;
}


.mini-cart-link {
    display: inline-block;
    position: relative;
    .badge {
        position: absolute;
        top: -3px;
        right: -10px;
        font-size: 10px;
        background-color: $brown !important;
    }

}

ul.side-menu {
    list-style: none;
    padding-left: 0;
    li {
        display: block;
        a {
            display:block;
            padding: 10px 5px;
            text-align: center;
            color: #000000;
            text-transform: uppercase;
            text-decoration: none !important;
            &:hover {
                background-color: #ccc;
            }
        }
        &:after {
            display: none !important;
        }
        &.active {
            a {
                background-color: $main-color !important;
                color: $white !important;
            }
        }
        & + li {
            border-top: 1px solid #ccc;
        }
    }
}

.slide-enter-active,
.slide-leave-active,
.slide-enter-active,
.slide-leave-active {
    transition-duration: 0.5s;
    transition-delay: 150ms;
    transition-property: height, opacity, transform;
    transition-timing-function: cubic-bezier(1, 0, 0.1, 1);
    overflow: hidden;
}

.slide-enter,
.slide-leave-active {
    opacity: 0;
    transform: translate(2em, 0);
    transition-property: height, opacity,transform;

    overflow: hidden;
    height: 100vh;
}

.slide-leave-active,
.slide-enter {
    opacity: 0;
    transform: translate(-2em, 0);

}

.fade-enter-active,
.fade-leave-active {
    transition-duration: 0.3s;
    transition-property: height, opacity;
    transition-timing-function: ease;
    overflow: hidden;
}

.fade-enter,
.fade-leave-active {
    opacity: 0;
    height: 100vh;
}

.list-complete-item {
    transition: all 500ms;
    display: inline-block;

}
.list-complete-enter, .list-complete-leave-to {
    opacity: 0;
    transform: scale(0);
}
.list-complete-leave-active {
    position: absolute;
}




.cart-row {
    background-color: $dark-grey;
    border: 1px solid #d2d0d0
}
